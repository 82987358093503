import React, { useCallback } from 'react';
import { Control } from 'react-hook-form';
import { FormInput } from 'components';
import { shops, ShopTypes } from 'api';
import { AutocompleteSearchItem } from 'api/types/common';
import { useStoreContext } from 'store';
import FormInputProps from './FormInput/FormInputProps';

interface Props
  extends Omit<
    FormInputProps.AutocompleteSearchInput,
    'onChange' | 'inputType' | 'queryKey' | 'queryFn'
  > {
  control: Control<any> | undefined;
  onChange: (shop: ShopTypes.ItemData) => void;
  name?: string;
  disabled?: boolean;
}

const ShopInput: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    control,
    onChange: onShopChange,
    name,
    disabled,
    autoSelectFirstOption,
    findInitialValueInOptions,
  } = props;
  const {
    state: { user },
  } = useStoreContext();

  const handleShopChange = useCallback(
    (
      value: AutocompleteSearchItem | AutocompleteSearchItem[] | null,
      onChange: (value: string | string[] | null) => void,
    ) => {
      if (value && !Array.isArray(value) && typeof value === 'object') {
        const shop = value as unknown as ShopTypes.ItemData;

        onShopChange(shop);
        onChange(shop._id.$oid);
      }
    },
    [onShopChange],
  );

  return (
    <FormInput
      disabled={disabled}
      rules={{ required: true }}
      control={control}
      name={name || 'shop_id'}
      inputType="autocompleteSearch"
      label="Shop"
      disableClearable
      autoSelectFirstOption={autoSelectFirstOption ?? true}
      findInitialValueInOptions={findInitialValueInOptions ?? true}
      queryKey={shops.endpoints.getFlatList}
      onChange={handleShopChange}
      initialValueId={user?.shop_id?.$oid}
      queryFn={() =>
        shops
          .getFlatList()
          .then((res) =>
            res.filter((r) => r.status === ShopTypes.StatusEnum.Active),
          )
      }
    />
  );
};

export default ShopInput;
