import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TextField,
  Autocomplete,
  AutocompleteInputChangeReason,
  debounce,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { AutocompleteSearchItem } from 'api/types/common';
import { Loader } from 'components/index';
import FormInputProps from '../FormInputProps';
import { ArrowDownTinIcon } from '../../../icons';
import AutocompleteSearchOption from './AutocompleteSearchOption';

type Props<T extends FieldValues = any> = Omit<
  FormInputProps.AutocompleteSearchInput,
  'inputType'
> &
  UseControllerProps<T>;

const AutocompleteSearchInput: React.FC<Props> = (props: Props) => {
  const {
    queryFn,
    queryKey,
    label,
    placeholder,
    multiple,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    autoSelectFirstOption,
    onChange,
    disableClearable,
    initialValue: fieldInitialValue,
    findInitialValueInOptions,
    fieldValueKey,
    disabled,
    initialValueId,
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const initialValue = useMemo(() => {
    if (fieldInitialValue) {
      return fieldInitialValue;
    }

    if (field.value && typeof field.value === 'string') {
      return {
        name: field.value,
        _id: { $oid: field.value },
      };
    }

    return multiple ? [] : null;
  }, [field.value, fieldInitialValue, multiple]);

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<
    AutocompleteSearchItem | AutocompleteSearchItem[] | null
  >(initialValue);

  const { data, isLoading } = useQuery(
    [queryKey, inputValue],
    () => queryFn(inputValue),
    {
      // enabled: autoSelectFirstOption ? true : open,
      initialData: [],
      refetchOnWindowFocus: false,
    },
  );

  const handleValueChange = useCallback(
    (
      event: unknown,
      newValue: AutocompleteSearchItem | AutocompleteSearchItem[] | null,
    ) => {
      setValue(newValue);

      if (onChange) {
        onChange(newValue, field.onChange);
      } else if (Array.isArray(newValue)) {
        if (fieldValueKey === '_id') {
          const ids = (newValue || []).map((v) => v._id.$oid);
          field.onChange(ids);
        } else {
          const names = (newValue || []).map((v) => v.name);
          field.onChange(names);
        }
      } else if (newValue && typeof newValue === 'object') {
        if (fieldValueKey === '_id') {
          field.onChange(newValue._id.$oid);
        } else {
          field.onChange(newValue.name);
        }
      } else {
        field.onChange(newValue);
      }
    },
    [field, fieldValueKey, onChange],
  );

  const handleInputChange = debounce(
    (
      event: unknown,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'reset') {
        return;
      }
      setInputValue(newInputValue);
      if (reason === 'clear') {
        field.onChange(null);
      }
    },
    500,
  );

  useEffect(() => {
    if (data && !field.value) {
      if (autoSelectFirstOption && !initialValueId) {
        handleValueChange(null, data[0]);
      }

      if (initialValueId) {
        const v = data.find((d) => d._id.$oid === initialValueId);
        if (v) {
          handleValueChange(null, v);
        }
      }
    }
  }, [
    autoSelectFirstOption,
    data,
    field.value,
    handleValueChange,
    initialValueId,
  ]);

  const options = useMemo(() => {
    const v = value || initialValue;
    const arr = Array.isArray(v)
      ? [...v, ...data]
      : [...(v ? [v] : []), ...data];

    return uniqBy(arr, 'name');
  }, [data, initialValue, value]);

  useEffect(() => {
    if (findInitialValueInOptions && data) {
      if (multiple) {
        const selected = data.filter((d) => field.value?.includes(d._id.$oid));
        setValue(selected);
      } else {
        const selected = data.find((d) => d._id.$oid === field.value);
        setValue(selected || null);
      }
    }
  }, [data, field.value, findInitialValueInOptions, multiple]);

  return (
    <Autocomplete
      popupIcon={<ArrowDownTinIcon />}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      multiple={multiple}
      disabled={disabled}
      disableCloseOnSelect={multiple}
      value={value}
      clearOnEscape={!disableClearable}
      disableClearable={disableClearable}
      filterOptions={(x) => x}
      getOptionDisabled={(o) => o?.disabled || false}
      autoComplete
      onChange={handleValueChange}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(
        option: AutocompleteSearchItem,
        value: AutocompleteSearchItem,
      ) => option._id.$oid === value._id.$oid}
      options={options || []}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={field.ref}
          name={field.name}
          label={label}
          placeholder={
            placeholder ||
            (typeof label === 'string' ? `Search ${label}` : undefined)
          }
          error={!!error}
          helperText={error && error.message}
          InputLabelProps={{
            shrink: true,
            ...params.InputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            notched: false,
            endAdornment: isLoading ? (
              <>
                {params.InputProps.endAdornment}
                <Loader circularProgressProps={{ size: 20 }} />
              </>
            ) : (
              params.InputProps.endAdornment
            ),
          }}
        />
      )}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.name
      }
      renderOption={(liElementProps, option) => (
        <AutocompleteSearchOption
          liElementProps={liElementProps}
          option={option}
        />
      )}
      ChipProps={{
        color: 'primary',
      }}
    />
  );
};

export default AutocompleteSearchInput;
