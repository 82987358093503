import React, { ChangeEvent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormCard, FormContainer, FormInput, ShopInput } from 'components';
import {
  DealTypes,
  influencers,
  negotiations,
  NegotiationTypes,
  users,
} from 'api';
import { getFieldsWithValues, getModifiedFields } from 'helpers/getFormFields';
import { useApiRequest, useFiltersSearchParams } from 'hooks';
import { AutocompleteSearchItem } from 'api/types/common';
import { PersonOutlined } from '@mui/icons-material';
import { Alert, Collapse, Grid, Link } from '@mui/material';
import DEALS from 'constants/deals';
import NEGOTIATIONS from 'constants/negotiations';
import BRANDS from 'constants/brands';
import COUNTRIES from 'constants/countries';
import { arrayToOptions } from 'helpers/common';
import { appLinks } from 'routes/links';
import { useStoreContext } from 'store';
import { BrandStatusEnum } from '../../../api/types/Brands';

interface Props {
  id: string | undefined;
  initialData: NegotiationTypes.ItemCreateParams;
  initialInfluencer?: AutocompleteSearchItem;
  drawer?: boolean;
  drawerTitle?: string;
}

const Form: React.FC<Props> = (props: Props): JSX.Element => {
  const { initialData, id, initialInfluencer, drawer, drawerTitle } = props;
  const navigate = useNavigate();
  const {
    state: { refetchTableData },
  } = useStoreContext();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { dirtyFields, isDirty, isSubmitting },
  } = useForm<NegotiationTypes.ItemCreateParams>({
    defaultValues: initialData,
  });

  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const [isBlacklistedInfluencer, setIsBlacklistedInfluecner] = useState(false);

  const { requestFn } = useApiRequest(
    id ? (data) => negotiations.update(id, data) : negotiations.create,
  );

  const onSubmit = useCallback(
    async (data: NegotiationTypes.ItemCreateParams) => {
      const values = id
        ? getModifiedFields(dirtyFields, data)
        : getFieldsWithValues(data);

      const res = await requestFn({
        args: values,
        successMessage: `Negotiation ${id ? 'updated' : 'created'}`,
      });

      if (res) {
        if (drawer) {
          updateFiltersSearchParams({
            negotiationId: undefined,
            drawerMode: undefined,
          });

          if (refetchTableData) {
            await refetchTableData();
          }
        } else {
          navigate(appLinks.negotiations.details.as(res.negotiation_id), {
            replace: true,
            state: {
              from: id ? 'edit' : 'create',
            },
          });
        }
      }
    },
    [
      id,
      dirtyFields,
      requestFn,
      drawer,
      updateFiltersSearchParams,
      refetchTableData,
      navigate,
    ],
  );

  const strategy = watch('strategy');
  const influencerId = watch('influencer_id');

  const handleStrategyChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      onChange: (value: string | string[] | undefined) => void,
    ) => {
      const value = event.target.value as DealTypes.StrategiesEnum;
      const channelOptions = DEALS.CHANNEL_OPTIONS[value];

      setValue('channel', channelOptions[0]);

      onChange(value);
    },
    [setValue],
  );

  const handleInfluencerChange = useCallback(
    (
      value: AutocompleteSearchItem | AutocompleteSearchItem[] | null,
      onChange: (value: string | string[] | null) => void,
    ) => {
      if (value && !Array.isArray(value) && typeof value === 'object') {
        onChange(value._id.$oid);
      }

      if (value && !Array.isArray(value) && Array.isArray(value.status)) {
        setIsBlacklistedInfluecner(
          value.status.some((s) => s === BrandStatusEnum.Blacklist),
        );
      }
    },
    [],
  );

  return (
    <FormContainer
      variant={drawer ? 'drawer' : 'page'}
      drawerTitle={drawerTitle}
      pageLink={id ? appLinks.negotiations.edit.as(id) : undefined}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      disabled={!!id && !isDirty}
    >
      <Collapse
        in={!!influencerId && isBlacklistedInfluencer}
        sx={{
          position: 'sticky',
          top: 97,
          zIndex: 2,
          pb: 2,
        }}
      >
        <Alert severity="error" variant="filled">
          The selected influencer is blacklisted. Please verify the validity of
          this deal.
          <Link
            href={appLinks.influencers.details.as(influencerId)}
            target="_blank"
            display="block"
          >
            View Influencer Details
          </Link>
        </Alert>
      </Collapse>
      <FormCard title="General information" avatar={<PersonOutlined />}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ShopInput
              control={control}
              autoSelectFirstOption={!id}
              onChange={(shop) => {
                setValue('brand', shop.brand);
                setValue('country_team', shop.country_team);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInput
              control={control}
              name="brand"
              inputType="select"
              label="Brand"
              options={BRANDS.OPTIONS}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInput
              control={control}
              name="country_team"
              inputType="select"
              label="Country team"
              options={COUNTRIES.OPTIONS}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="influencer_id"
              inputType="autocompleteSearch"
              label="Influencer"
              queryFn={influencers.autocompleteSearch}
              queryKey={influencers.endpoints.autocompleteSearch}
              onChange={handleInfluencerChange}
              initialValue={initialInfluencer}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              control={control}
              inputType="autocompleteSearch"
              name="lead"
              label="Lead"
              queryFn={users.autocompleteSearch}
              queryKey={users.endpoints.autocompleteSearch}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="strategy"
              inputType="select"
              label="Channel"
              options={DEALS.STRATEGY_OPTIONS}
              onChange={handleStrategyChange}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="channel"
              inputType="select"
              label="Sub channel"
              options={arrayToOptions(DEALS.CHANNEL_OPTIONS[strategy])}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="status"
              inputType="select"
              label="Status"
              options={NEGOTIATIONS.STATUS_OPTIONS}
              rules={{
                required: true,
              }}
            />
          </Grid>
        </Grid>
      </FormCard>
    </FormContainer>
  );
};

export default Form;
