import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { Alert, Collapse, Grid, Link } from '@mui/material';
import { PersonOutlined } from '@mui/icons-material';
import { DealTypes, influencers, ShopTypes } from 'api';
import BRANDS from 'constants/brands';
import COUNTRIES from 'constants/countries';
import { AutocompleteSearchItem } from 'api/types/common';
import { FormCard, FormInput, ShopInput } from 'components';
import DEALS from 'constants/deals';
import { CountriesEnum } from 'api/types/Countries';
import { BrandStatusEnum } from 'api/types/Brands';
import { appLinks } from 'routes/links';

interface Props {
  id?: string;
  control: Control<DealTypes.ItemCreateParams> | undefined;
  setValue: UseFormSetValue<DealTypes.ItemCreateParams>;
  watch: UseFormWatch<DealTypes.ItemCreateParams>;
  initialInfluencer?: AutocompleteSearchItem;
}

const GeneralBlock = (props: Props): JSX.Element | null => {
  const { control, setValue, initialInfluencer, watch, id } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [isBlacklistedInfluencer, setIsBlacklistedInfluecner] = useState(false);

  const startDate = watch('start_date');
  const endDate = watch('end_date');
  const country = watch('country_team');
  const shopId = watch('shop_id');
  const influencerId = watch('influencer_id');

  const $prevCountry = useRef<CountriesEnum>();

  const handleInfluencerChange = useCallback(
    (
      value: AutocompleteSearchItem | AutocompleteSearchItem[] | null,
      onChange: (value: string | string[] | null) => void,
    ) => {
      if (value && !Array.isArray(value) && typeof value === 'object') {
        onChange(value._id.$oid);
      }

      if (value && !Array.isArray(value) && Array.isArray(value.status)) {
        setIsBlacklistedInfluecner(
          value.status.some((s) => s === BrandStatusEnum.Blacklist),
        );
      }
    },
    [],
  );

  const handleShopChange = useCallback(
    (shop: ShopTypes.ItemData) => {
      setValue('brand', shop.brand);
      setValue('country_team', shop.country_team);
    },
    [setValue],
  );

  useEffect(() => {
    if (id || !shopId) return;

    const date = dayjs(startDate);
    const countryDuration = DEALS.DEAL_DURATIONS[country];
    const countryEndDate = date.add(countryDuration, 'days');

    if (countryEndDate.isSame(endDate)) return;

    const updateEndDate = () => {
      setValue('end_date', countryEndDate.toISOString());
      enqueueSnackbar({
        variant: 'info',
        message: `End date was changed by country rule "Start date + ${countryDuration} days"`,
      });
    };

    if ($prevCountry.current) {
      const prevCountryDuration = DEALS.DEAL_DURATIONS[$prevCountry.current];
      const prevCountryEndDate = date.add(prevCountryDuration, 'days');

      if (!endDate || !countryEndDate.isSame(prevCountryEndDate)) {
        updateEndDate();
      }
    } else if (!endDate) {
      updateEndDate();
    }

    $prevCountry.current = country;
  }, [country, endDate, enqueueSnackbar, id, setValue, shopId, startDate]);

  return (
    <>
      <Collapse
        in={!!influencerId && isBlacklistedInfluencer}
        sx={{
          position: 'sticky',
          top: 97,
          zIndex: 2,
        }}
      >
        <Alert severity="error" variant="filled">
          The selected influencer is blacklisted. Please verify the validity of
          this deal.
          <Link
            href={appLinks.influencers.details.as(influencerId)}
            target="_blank"
            display="block"
          >
            View Influencer Details
          </Link>
        </Alert>
      </Collapse>
      <FormCard title="General details" avatar={<PersonOutlined />}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="influencer_id"
              inputType="autocompleteSearch"
              label="Influencer"
              queryFn={influencers.autocompleteSearch}
              queryKey={influencers.endpoints.autocompleteSearch}
              onChange={handleInfluencerChange}
              initialValue={initialInfluencer}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ShopInput control={control} onChange={handleShopChange} />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="brand"
              inputType="select"
              label="Brand"
              options={BRANDS.OPTIONS}
              rules={{
                required: true,
                onChange: () => {
                  setValue('product_categories', []);
                  setValue('products', []);
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              control={control}
              name="country_team"
              inputType="select"
              label="Country team"
              options={COUNTRIES.OPTIONS}
              rules={{
                required: true,
              }}
            />
          </Grid>
        </Grid>
      </FormCard>
    </>
  );
};

export default GeneralBlock;
